import React, {Component} from 'react';
import Layout from '../components/layout';
import CatoImg from '../components/cato-image';
import SideCarousel from '../components/side-carousel';
import Customer from '../components/customer';
import ResourcesFooterShortText from '../components/resources-footer-short-text';
import WhereDoYouWantToStart from "../components/where-do-banner";
import {graphql, Link} from "gatsby";
import {mapGartner, mapStickyBanner, videoSectionMapper} from '../common/utils';
import GartnerImage from '../assets/images/components/gartner-strip/12x_customer_new.png';
import Lightbox from '../components/lightbox';
import GartnerStrip from '../components/gartner-strip';
import VideoWithLightbox from '../components/video-lightbox';
import VideoLightboxMapper from '../components/video-lightbox/mapper';
import '../assets/styles/pages/_customer-lobby.scss';
import '../assets/styles/responsive/pages/customer-lobby-small-desktop.scss';
import '../assets/styles/responsive/pages/customer-lobby-tablet.scss';
import '../assets/styles/responsive/pages/customer-lobby-mobile.scss';
import {LANG} from "../common/consts";

const generateSlides = ({image, desc, by, position}, i) => {
    return <div key={i} className={'outline-fix'} style={{outline: 'none'}}>
        <div className={'slide'}>
            <img src={image.publicURL} alt="" className="quotes"/>
            <div>
                <h2>{desc}</h2>
                <div className={'hr'} />
                <span>{by}</span>,<span>{position}</span>
            </div>
        </div>
    </div>
}

const CustomerSlider = ({data}) => {

    if(!data) return null;
    return (
        <div className={'customer-slider'}>
            <SideCarousel data={data} generateSlides={generateSlides}/>
        </div>
    )
}

//TODO: clean up this render to stop forcing rerenders on quotes and images
let quoteBannerIndex = 0;

class CustomerPage extends Component {

    constructor(props) {
        super(props)

        this.state = {
            quoteBannerIndex: 0,
            lightboxActive: false,
        }
    }


    componentWillUnmount() {
        quoteBannerIndex = 0;
    }

    componentDidUpdate(prevProps) {
        //variable is doubled in state for
        if(!prevProps.revisionData && this.props.revisionData) {
            quoteBannerIndex = 0;
            this.setState({...this.state, quoteBannerIndex: 0});
        }
    }

    setLightboxActive = () => {
        quoteBannerIndex = 0;
        const active = this.state.lightboxActive;
        this.setState({...this.state, lightboxActive: !active});
    };

    //TODO maky this an actual image
    customerImg = (item) => {
        const {quotesAndBanner} = this.props.data.wpPage;
        const {gartnerStrip: {gartnerCustomerImageText}} = this.props.data.gartnerText;
        // const {quoteBannerIndex} = this.state;
        const currRow = quotesAndBanner.customerLobbyQuoteAndBanner[quoteBannerIndex];

        if(currRow !== undefined) {
            const img = currRow.customerLobbyBanner
                ? currRow.customerLobbyBanner
                :
                null

            const div = img ?
                <Link
                    to={currRow.customerLobbyBannerLink}
                    className={'customer-image'}>
                    <CatoImg img={img}/>
                </Link>
                :
                null;

            quoteBannerIndex++;

            if (currRow.customerLobbyGartnerLightbox) {
                return (<div className="customer-image lightbox" style={{ cursor: 'pointer' }}
                             onClick={this.setLightboxActive}>
                        <img src={GartnerImage}/>
                        <div className="text" dangerouslySetInnerHTML={{__html: gartnerCustomerImageText}} />
                    </div>)
            }

            return div
        }

    };

    handleQuoteData = (banners) => {

        if(!banners[quoteBannerIndex]) return

        const source = banners[quoteBannerIndex];

        return source.customerLobbyQuotes.map(item => ({
            desc: item.customerLobbyQuote,
            by: item.customerLobbyCustomerName,
            position: item.customerLobbyPosition,
            image: this.props.data['quotes']
        }))

    };

    handleLangURL = (path) => {
        const pathArray = path.split('/');
        pathArray.shift();

        pathArray.pop()

        if(pathArray[1] !== 'customers') {
            pathArray.splice(1, 1, 'customers');
        }

        // const newPath = pathArray.join('/');

        return `/${pathArray.join('/')}`;
    }

    generateMiddleSection = (acf) => {

        const lang = this.props.pageContext.lang;

        const {quotesAndBanner} = this.props.data.wpPage;

        const items = acf.customerPages;

        return  items.map((item, i) => {

            const before = (i === 10 || i === 22 || i === 34)
                ?
                this.customerImg(item)
                :
                null;

            //TODO make this programmatic
            const after = ((i === 6) || i === 18 || i === 30)
            && quotesAndBanner.customerLobbyQuoteAndBanner[quoteBannerIndex]
                && quotesAndBanner.customerLobbyQuoteAndBanner[quoteBannerIndex].customerLobbyQuotes ? <CustomerSlider data={this.handleQuoteData(quotesAndBanner.customerLobbyQuoteAndBanner)} /> : null;

            const paths = item.postInternalUrl && item.postInternalUrl.postUrl
                ?
                item.language.code === LANG.ENG
                    ?
                    item.postInternalUrl.postUrl
                    :
                    this.handleLangURL(item.postInternalUrl.postUrl)
                :
                null;

            return (
                <div key={`customer-${i}`} className={`${after ? 'with-after' : before ? 'with-before' : ''}`}>
                    {before}
                    <Customer
                        path={paths}
                        title={item.customerInnerHeaderWithQuoteParent && item.customerInnerHeaderWithQuoteParent.customerInnerLobbyPageHeading ? item.customerInnerHeaderWithQuoteParent.customerInnerLobbyPageHeading : item.title}
                        desc={item.customerInnerHeaderWithQuoteParent.customerIndustryType ? item.customerInnerHeaderWithQuoteParent.customerIndustryType : null}
                        logo={item.customerInnerHeaderWithQuoteParent.customerCustomerLogo ? item.customerInnerHeaderWithQuoteParent.customerCustomerLogo : null}
                    />
                    {after}
                </div>
            )
        })
    }

    render() {

        const {data} = this.props;
        const {wpPage} = data;
        const {customerPagesParent, videoLightboxSlidesParent, videoSection, headerSideAlignedTextCustomerLobby, whereDoYouWantToStart, resourcesTrioFooter} = wpPage;

        const middleSection = customerPagesParent && customerPagesParent.customerPages && customerPagesParent.customerPages.length ? this.generateMiddleSection(customerPagesParent) : null;

        const youtube = videoSection.videos ? videoSectionMapper(videoSection.videoSectionHeadline, videoSection.videos) : null;

        const stickyBanner = mapStickyBanner(wpPage);

        const gartner = mapGartner(wpPage);

        return (
            <Layout
                className="customer-page"
                lang={this.props.pageContext.lang}
                breadcrumbs={[{title: wpPage.title, path: this.props.location.pathname}]}
                whiteMenu={true}
                yoastData={wpPage.seo || null}
                seoTitle={wpPage.pageSeoTitle.pageSeoTitle}
                title={wpPage.title}
                gartner={gartner}
                seoPath={this.props.location.pathname}
                translations={this.props.pageContext.translations}
                wpid={wpPage.databaseId}

                {...{stickyBanner}}
                {...{youtube}}
            >
                <section className={'section-1'}>
                    {headerSideAlignedTextCustomerLobby && headerSideAlignedTextCustomerLobby.headerSideSubHeadline && <h1 dangerouslySetInnerHTML={{__html: headerSideAlignedTextCustomerLobby.headerSideSubHeadline}}/>}
                    {headerSideAlignedTextCustomerLobby && headerSideAlignedTextCustomerLobby.headerSideSubSubheading && <h2 dangerouslySetInnerHTML={{__html: headerSideAlignedTextCustomerLobby.headerSideSubSubheading}} />}
                    {headerSideAlignedTextCustomerLobby && headerSideAlignedTextCustomerLobby.headerSideSubParagraph && <h3 dangerouslySetInnerHTML={{__html: headerSideAlignedTextCustomerLobby.headerSideSubParagraph}}/>}
                </section>
                {videoLightboxSlidesParent && videoLightboxSlidesParent.videoLightboxSlides && videoLightboxSlidesParent.videoLightboxSlides.length &&
                    <section className="videos">
                        <VideoWithLightbox
                            items={videoLightboxSlidesParent.videoLightboxSlides.map(item => VideoLightboxMapper(item))}
                        />
                    </section>
                }
                <section className={'section-customers'}>
                    {middleSection}
                </section>
                {whereDoYouWantToStart && whereDoYouWantToStart.whereDoHeadline && whereDoYouWantToStart.whereDoButtonLinks &&
                    <WhereDoYouWantToStart links={whereDoYouWantToStart.whereDoButtonLinks} headline={whereDoYouWantToStart.whereDoHeadline}/>
                }
                {resourcesTrioFooter && resourcesTrioFooter.resourcesTrioResources &&
                    <ResourcesFooterShortText
                        data={resourcesTrioFooter.resourcesTrioResources}
                    />
                }
                <Lightbox active={this.state.lightboxActive} setActive={this.setLightboxActive}>
                    <GartnerStrip
                        klass="white"
                    />
                </Lightbox>
            </Layout>
        )
    }
}

export default CustomerPage;

export const pageQuery = graphql`
    query customerLobbyPageQuery($id: String!) {
        wpPage(id: { eq: $id }) {
            title
            content
            slug
            id
            databaseId
            date(formatString: "MMMM DD, YYYY")
            path: uri
            ...YoastData
            pageSeoTitle: pageSeoTitle {
                pageSeoTitle
            }
            headerSideAlignedTextCustomerLobby {
                headerSideSubHeadline
                headerSideSubSubheading
                headerSideSubParagraph
            }
            customerPagesParent {
                customerPages {
                    ... on WpPage {
                       title
                       language {
                        code
                       }
                       customerInnerHeaderWithQuoteParent {
                           customerIndustryType
                           customerCustomerLogo {
                                altText
                                localFile {
                                    childImageSharp {
                                        fixed(height: 45) {
                                            ...GatsbyImageSharpFixed_noBase64
                                        }
                                    }
                                    extension
                                    publicURL
                                }
                            }
                            customerInnerLobbyPageHeading
                        }
                        postInternalUrl {
                            postUrl
                        }
                    }
                }
            }
            quotesAndBanner {
                customerLobbyQuoteAndBanner {
                    customerLobbyQuotes {
                        customerLobbyQuote
                        customerLobbyCustomerName
                        customerLobbyPosition
                    }
                    customerLobbyBanner {
                        altText
                        localFile {
                            childImageSharp {
                                fluid(maxWidth: 340){
                                ...GatsbyImageSharpFluid_noBase64
                                }
                            }
                            extension
                            publicURL
                        }
                    }
                    customerLobbyBannerLink
                    customerLobbyGartnerLightbox
                }
            }
            videoLightboxSlidesParent {
                videoLightboxSlides {
                    videoLightbooxCode
                    videoLightboxVideoImage {
                        localFile {
                            publicURL
                        }
                    }
                    videoLightboxTitle
                    videoLightboxSubtitle
                    videoLightboxDescription
                    videoLightboxButtonLink
                    videoLightboxButtonLabel
                }
            }
            whereDoYouWantToStart {
                whereDoHeadline
                whereDoButtonLinks {
                    whereDoLink
                    whereDoButtonText
                }
            }
            ...StickyBanner
            ...VideoSection
            ...ResourcesTrio
            ...GartnerStrip
        }
        gartnerText: wpPage(databaseId: { eq: 12 }) {
            id
            gartnerStrip {
                gartnerCustomerImageText
            }
        }
        quotes: file(relativePath: { eq: "quotes dark.svg"}) {
            extension
            publicURL
        }
    }`;