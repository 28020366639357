import React from 'react';
import {Link} from 'gatsby';
import './style.scss';

const WhereDoYouWantToStart = (props) => (
    <section className={'section-where-to-start'}>
        <h1>{props.headline}</h1>
        <div className={'links'}>
            {props.links.map((link, i) => (
                <Link
                    key={`where-do-${i}`}
                    className="bullet"
                    to={link.whereDoLink}
                    dangerouslySetInnerHTML={{__html: link.whereDoButtonText}}
                />

            ))}
        </div>
    </section>
);

WhereDoYouWantToStart.defaultProps = {
    headline: 'Where do you want to start'
}

export default WhereDoYouWantToStart;