import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import CatoImg from '../cato-image';
import './style.scss';


class SideCarousel extends Component {

    generateSlides = () => {
        const {data, generateSlides} = this.props;

        if(generateSlides) return data.map((item, i) => generateSlides(item, i));

        return data.map((item, i) => {
            const image = item.image ?
                <div className="image-wrapper"><CatoImg img={item.image} alt={item.alt ? item.alt : 'slide image'} style={{filter: 'grayscale(1)'}}/></div>
                :
                null;

            const text = item.text ?
                <div className="text-wrapper">
                    <p style={{fontWeight: 400}}>{item.text}</p>
                    {item.bold && <div className="bold">{item.bold}</div>}
                    {item.subText && <div className="subText">{item.subText}</div>}
                </div>
                :
                null;


            return (
                <div className="side-carousel-slide" key={`side-carousel-slide-${i}`}>
                    {text}
                    {image}
                </div>
            )
        })
    };

    render() {
        const slides = this.generateSlides();
        return (
            <Slider {...this.props} className="side-carousel">
                {slides}
            </Slider>
        )
    }
}

SideCarousel.propTypes = {
  dots: PropTypes.bool,
    infinite: PropTypes.bool,
    speed: PropTypes.number,
    slidesToShow: PropTypes.number,
    slidesToScroll: PropTypes.number,
    data: PropTypes.arrayOf(PropTypes.object),
    generateSlides: PropTypes.func
};

SideCarousel.defaultProps = {
    dots: true,
    infinite: true,
    slidesToScroll: 1,
    slidesToShow: 1,
    arrows: false,
    generateSlides: null
};

export default SideCarousel;